@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Quicksand&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Quicksand", "Oswald", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change to the desired background color */
}

::-webkit-scrollbar-thumb {
  background-color: #f5ac2f; /* Change to the desired thumb color */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  transition: opacity 0.2s ease-in-out; /* Change to the desired thumb color on hover */
}
code {
  font-family: "Quicksand", "Oswald", sans-serif;
}
h1,
h2,
h3,
h4 {
  font-family: "Oswald", sans-serif;
}
h1 {
  font-weight: bold;
  font-size: x-large;
  letter-spacing: 3px;
}
p {
  font-family: "Quicksand", sans-serif;
}
button {
  border-radius: 2px;
}
button.disabled {
  background-color: #ccc; /* Gray background color */
  color: #999; /* Gray text color */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
  /* You can add more styles as needed */
}
.bg-virtual{
  background-image: url('./Assets/image/virtual-seminar.jpg');
}
.bg-firstaid{
  background-image: url('./Assets/image/Firstaid-training.png');
}
.bg-derash{
  background-image: url('./Assets/image/Derash.png');
}
.bg-cpd{
  background-image: url('./Assets/image/cpd-training.png');
}
.bg-news1{
  background-image: url('./Assets/newsfeedimage/news1.jpg');
}
.bg-news2{
  background-image: url('./Assets/newsfeedimage/news2.jpg');
}
.bg-news3{
  background-image: url('./Assets/newsfeedimage/news3.jpg');
}
.bg-news4{
  background-image: url('./Assets/newsfeedimage/news4.jpg');
}
.divider {
  border: 2px solid #f5ac2f;
  width: 10rem;
  border-radius: 20px;
  margin-top: 9px;
}
.grid-item {
  /* Your existing styles for individual grid items */
  position: relative;
}

.overlay-text {
  /* Styles for the text overlay */
  position: absolute;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #f5ac2f; /* Adjust the opacity and color as per your preference */
  color: #fff; /* Text color */
  font-size: 18px; /* Text font size */
  opacity: 0; /* Initially, the text is hidden */
  transition: opacity 0.2s ease-in-out; /* Smooth transition effect */
}
input , select{
  border: 2px solid;
  border-color: #c2c2c2;
  border-radius: 3px;
}
input:hover,
input:focus,
select:hover,
select:focus{
  border-color: #f5ac2f;
  transition: all 3.s ease-in-out;
}
.grid-item:hover .overlay-text {
  cursor: pointer;
  opacity: 85%; /* Show the text on hover */
}
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 8px solid;
  border-color: #f5ac2f #0000;
  animation: s1 1s infinite;
}
@keyframes s1 {
  to {
    transform: rotate(0.5turn);
  }
}
